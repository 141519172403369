import { useRouteMatch } from 'react-router-dom';

import { getOsaQuoteService, useApiRequest } from '@eir/services';
import { Page, PageWrapper } from '@eir/letter';

import Page1 from './components/Page1';
import Page2 from './components/Page2';
import Page3 from './components/Page3';
import Page4 from './components/Page4';
import PageContainer from '../../components/PageContainer';
import PageFooter from '../../components/PageFooter';
import PageHeader from '../../components/PageHeader';

const GroupContractSwedenView = () => {
  const documentName = 'Gruppavtal 2.8';
  const match = useRouteMatch('/se/q/:quoteId');
  const { data } = useApiRequest(
    getOsaQuoteService('AvonovaAPI', match.params.quoteId)
  );

  return (
    data && (
      <PageWrapper>
        <Page
          pageHeader={<PageHeader />}
          content={
            <PageContainer>
              <Page1 data={data} />
            </PageContainer>
          }
          pageFooter={<PageFooter documentName={documentName} pageNumber={1} />}
        />
        <Page
          pageHeader={<PageHeader />}
          content={
            <PageContainer>
              <Page2 data={data} />
            </PageContainer>
          }
          pageFooter={<PageFooter documentName={documentName} pageNumber={2} />}
        />
        <Page
          pageHeader={<PageHeader />}
          content={
            <PageContainer>
              <Page3 data={data} />
            </PageContainer>
          }
          pageFooter={<PageFooter documentName={documentName} pageNumber={3} />}
        />
        <Page
          pageHeader={<PageHeader />}
          content={
            <PageContainer>
              <Page4 data={data} />
            </PageContainer>
          }
          pageFooter={<PageFooter documentName={documentName} pageNumber={4} />}
        />
      </PageWrapper>
    )
  );
};

export default GroupContractSwedenView;
